import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const TinderCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 1000, // 最大幅を増やす
  height: 450, // 高さを減らして横長にする
  position: 'relative',
  borderRadius: 10,
  overflow: 'hidden',
  boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  '&:hover': {
    transform: 'scale(1.03)',
    transition: 'transform 0.3s ease-in-out',
  },
}));

const CardOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)',
  padding: theme.spacing(2),
  color: 'white',
  height: '150px', // オーバーレイの高さを調整
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
}));

const CastList = () => {
  const [casts, setCasts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCasts = async () => {
      try {
        const response = await axios.get('https://us-central1-gggrace-gamer.cloudfunctions.net/api/casts');
        console.log('API Response:', response.data); // デバッグ用ログ
        setCasts(response.data);
      } catch (error) {
        console.error('キャスト情報の取得に失敗しました:', error);
        setError('キャスト情報の取得に失敗しました。');
      }
    };

    fetchCasts();
  }, []);

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container maxWidth="lg">
      <Box my={4} maxWidth="600px" mx="auto">
        <Box my={4}>
          <Typography 
            variant="h3" 
            component="h1" 
            align="center" 
            sx={{ fontFamily: 'M PLUS 1p, sans-serif', marginBottom: '0rem', fontSize: '5rem' }}
          >
            CAST
          </Typography>
          <Box 
            sx={{ 
              width: '40%', 
              height: '2px',
              backgroundColor: '#000',
              margin: '0rem auto'
            }} 
          />
          <Typography 
            variant="subtitle1" 
            component="h2" 
            align="center" 
            sx={{ fontFamily: 'M PLUS 1p, sans-serif', fontSize: '1rem' }}
          >
            キャスト一覧
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        {casts.map((cast) => {
          console.log('Cast data:', cast); // 各キャストのデータをログ出力
          const imageUrl = cast.imageUrl || 'https://via.placeholder.com/300x450?text=No+Image';
          console.log('Image URL:', imageUrl); // 画像URLをログ出力
          return (
            <Grid item key={cast.id} xs={12} sm={6} md={6} lg={4} sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
              <TinderCard>
                <CardMedia
                  component="img"
                  image={imageUrl}
                  alt={cast.name || '名前なし'}
                  sx={{
                    width: '100%',
                    height: '1800px', // 固定の高さを設定
                    objectFit: 'cover',
                  }}
                />
                <CardOverlay>
                  <Typography variant="h6" component="div" gutterBottom>
                    {cast.name || '名前なし'}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    好きなゲーム: {cast.favoriteGame || '未設定'}
                  </Typography>
                  <Button 
                    component={Link} 
                    to={`/cast/${cast.id}`} 
                    variant="contained" 
                    sx={{ 
                      backgroundColor: 'transparent', // アクセントカラー
                      color: '#fff',
                      padding: '7px 14px', // パディングを少し小さく設定
                      borderRadius: '8px', // 角丸に設定
                      fontSize: '14px', // フォントサイズを少し小さく設定
                      fontWeight: 'bold',
                      border: 'none',
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // 影を追加
                      textDecoration: 'underline', // アンダーラインを追加
                      transition: 'transform 0.2s, background-color 0.2s', // ホバー時のアニメーション
                      '&:hover': { // ホバー時のスタイル
                        backgroundColor: '#2980b9', // 色を暗くする
                        transform: 'scale(1.05)', // 少し拡大
                        cursor: 'pointer',
                      }
                    }} 
                  >
                    詳細を見る
                  </Button>
                </CardOverlay>
              </TinderCard>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default CastList;