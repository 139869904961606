import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate, useLocation, Navigate, BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import gggLogo from './assets/GGGraceLogo.png';
import axios from 'axios';
import { UserProvider } from './UserContext';

// コンポーネントのインポート
import Home from './components/Home'; // 新しく作成したHomeコンポーネントをインポート
import CastApplicationForm from './components/CastApplicationForm';
import CastList from './components/CastList';
import InterviewBooking from './components/InterviewBooking';
import Booking from './components/Booking';
import Register from './components/Register';
import VerifyEmail from './components/VerifyEmail';
import UserProfile from './components/UserProfile';
import AdminPage from './components/AdminPage';
import CastApplicationList from './components/CastApplicationList';
import ScheduleRegistration from './components/ScheduleRegistration';
import CastProfileEdit from './components/CastProfileEdit';
import CastDetail from './components/CastDetail';
import CurrencyPurchase from './components/CurrencyPurchase';
import RefundPolicy from './components/RefundPolicy';
import Policy from './components/policy';
import AntisocialExclusionPolicy from './components/antisocialexclusionpolicy';
import PrivacyPolicy from './components/privacypolicy';
import LegalNotice from './components/legalnotice';
import PurchaseSuccess from './components/PurchaseSuccess';

function App() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [bgColor, setBgColor] = useState('#FFFFFF'); // 背景を白に設定
  const [isSubMenuVisible, setSubMenuVisible] = useState(false); // サブメニューの表示状態
  const [isMenuOpen, setMenuOpen] = useState(false); // ハンバーガーメニューの状態管理
  const [isMobile, setIsMobile] = useState(false); // スマートフォン判定
  const [isCastDetailVisible, setCastDetailVisible] = useState(false); // CastDetailの表示状態を管理

  useEffect(() => {
    const checkUserAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(
            'https://us-central1-gggrace-gamer.cloudfunctions.net/api/user',
            {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          );
          setUser(response.data);
        } catch (error) {
          console.error('ユーザー情報の取得に失敗しました:', error);
          localStorage.removeItem('token');
          setUser(null);
        }
      }
    };

    checkUserAuth();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // スマートフォンのサイズを判定
    };

    handleResize(); // 初期判定
    window.addEventListener('resize', handleResize); // リサイズイベントのリスナーを追加

    return () => {
      window.removeEventListener('resize', handleResize); // クリーンアップ
    };
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
    setMenuOpen(false); // メニューを閉じる
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    alert('ログアウトしました。');
    navigate('/');
  };

  const handleHomeClick = () => {
    setSubMenuVisible(false);
    handleNavigation('/');
  };

  // FAQボタンのクリックイベント
  const handleFAQClick = () => {
    navigate('/refund-policy'); // RefundPolicyページのパスに変更
  };

  // 共通のレイアウト要素を関数として定義
  const commonLayout = (
    <>
      <div className="logo-container">
        <img src={gggLogo} alt="GGGrace Logo" className="logo" />
        <span className="logo-text">GGG</span>
      </div>
      
      {/* ハンバーガーメニュー */}
      {isMobile ? (
        <div className="hamburger-menu">
          {user ? ( // userが存在する場合のみ表示
            <span className="user-name">ようこそ、{user.username}さん</span>
          ) : (
            <span className="user-name">ようこそ、ゲストさん</span> // userがnullの場合の表示
          )}
          <button 
            className="hamburger-button" 
            onClick={() => setMenuOpen(!isMenuOpen)} // メニューの表示/非表示を切り替え
          >
            ☰
          </button>
          {isMenuOpen && (
            <div className={`menu-items ${isMenuOpen ? 'show' : ''}`}>
              <button onClick={handleHomeClick} className="nav-button">ホーム</button>
              <button onClick={() => handleNavigation('/cast-list')} className="nav-button">キャスト一覧</button>
              <button onClick={() => handleNavigation('/currency-purchase')} className="nav-button">通貨チャージ</button>
              {user && user.role === 'user' && (
                <button onClick={() => handleNavigation('/cast-application')} className="nav-button">キャスト申請</button>
              )}
              {user && user.role === 'Admin' && (
                <button onClick={() => handleNavigation('/admin')} className="nav-button">管理ページ</button>
              )}
              {user && (user.role === 'Admin' || user.role === 'Manager' || user.role === 'Cast') && (
                <button onClick={() => handleNavigation('/schedule-registration')} className="nav-button">スケジュール登録</button>
              )}
              {user && user.role === 'IntervieweeCast' && (
                <Link to="/interview-booking" className="nav-button">面談予約</Link>
              )}
              {user && user.role === 'Cast' && (
                <button onClick={() => handleNavigation('/cast-profile-edit')} className="nav-button">プロフィール編集</button>
              )}
              {user ? (
                <button onClick={handleLogout} className="nav-button">ログアウト</button>
              ) : (
                <button onClick={() => handleNavigation('/register')} className="nav-button create-account">アカウント作成</button>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="nav-buttons">
          <Link 
            to="/" 
            className="nav-button"
            onClick={handleHomeClick} // ホームボタンのクリックイベント
            onMouseEnter={() => setSubMenuVisible(true)} // ホームにカーソルが入ったとき
            onMouseLeave={() => setSubMenuVisible(false)} // ホームからカーソルが出たとき
          >
            ホーム
          </Link>
          {isSubMenuVisible && ( // サブメニューの表示
            <ul className="submenu" 
                onMouseEnter={() => setSubMenuVisible(true)} // サブメニューにカーソルが入ったとき
                onMouseLeave={() => setSubMenuVisible(false)} // サブメニューからカーソルが出たとき
            >
              <li>
                <Link to="/currency-purchase" onClick={() => { handleNavigation('/currency-purchase'); setSubMenuVisible(false); }}>通貨チャージ</Link> {/* サブメニュー項目 */}
              </li>
            </ul>
          )}
          <button 
            onMouseEnter={() => setSubMenuVisible(false)} // キャスト一覧にカーソルを合わせたときにサブメニューを非表示
            onClick={() => handleNavigation('/cast-list')} 
            className="nav-button"
          >
            キャスト一覧
          </button>
          {user && user.role === 'user' && (
            <button onClick={() => handleNavigation('/cast-application')} className="nav-button">キャスト申請</button>
          )}
          {user ? (
            <>
              <span className="user-name">ようこそ、{user.username || 'ゲスト'}さん</span>
              {(user.role === 'Admin' || user.role === 'Manager') && (
                <button onClick={() => handleNavigation('/admin')} className="nav-button">管理ページ</button>
              )}
              {user && (user.role === 'Admin' || user.role === 'Manager' || user.role === 'Cast') && (
                <button onClick={() => handleNavigation('/schedule-registration')} className="nav-button">スケジュール登録</button>
              )}
              {user && user.role === 'IntervieweeCast' && (
                <Link to="/interview-booking">面談予約</Link>
              )}
              {user && user.role === 'Cast' && (
                <button onClick={() => handleNavigation('/cast-profile-edit')} className="nav-button">プロフィール編集</button>
              )}
              <button onClick={handleLogout} className="nav-button">ログアウト</button>
            </>
          ) : (
            <button onClick={() => handleNavigation('/register')} className="nav-button create-account">アカウント作成</button>
          )}
        </div>
      )}
    </>
  );

  // ユーザーのロールに基づいてアクセスを制限するコンポーネント
  const ProtectedRoute = ({ element, allowedRoles }) => {
    if (!user) {
      return <Navigate to="/login" />;
    }
    if (allowedRoles.includes(user.role)) {
      return element;
    }
    return <Navigate to="/" />;
  };

  return (
    <UserProvider>
      <div className={`App`} style={{ background: bgColor }}>
        {commonLayout}
        <main>
          <Routes>
            <Route path="/" element={<Home />} /> {/* 新しいHomeコンポーネントをルートに設定 */}
            <Route path="/cast-application" element={<ProtectedRoute element={<CastApplicationForm />} allowedRoles={['user']} />} />
            <Route path="/cast-list" element={<CastList />} />
            <Route path="/interview-booking" element={<ProtectedRoute element={<InterviewBooking />} allowedRoles={['IntervieweeCast']} />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/admin" element={<ProtectedRoute element={<AdminPage />} allowedRoles={['Admin', 'Manager']} />} />
            <Route path="/cast-applications" element={<ProtectedRoute element={<CastApplicationList />} allowedRoles={['Admin', 'Manager']} />} />
            <Route path="/schedule-registration" element={<ProtectedRoute element={<ScheduleRegistration />} allowedRoles={['Admin', 'Manager', 'Cast']} />} />
            <Route path="/cast-profile-edit" element={<ProtectedRoute element={<CastProfileEdit />} allowedRoles={['Cast']} />} />
            <Route path="/casts" element={<CastList />} />
            <Route path="/cast/:id" element={<CastDetail setCastDetailVisible={setCastDetailVisible} />} />
            <Route path="/currency-purchase" element={<CurrencyPurchase />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/antisocialexclusionpolicy" element={<AntisocialExclusionPolicy />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/legal-notice" element={<LegalNotice />} />
            <Route path="/purchase-success" element={<PurchaseSuccess />} />
          </Routes>
        </main>
        {location.pathname === '/' && (
          <>
            <button 
              className="faq-button" 
              onClick={handleFAQClick}
              style={{ 
                position: 'absolute', 
                bottom: '10px', 
                left: '10px', 
                padding: '10px 20px', 
                backgroundColor: 'transparent', 
                color: 'black', 
                border: '1px solid #000', 
                borderRadius: '5px', 
                cursor: 'pointer' 
              }} 
            >
              FAQ
            </button>
            <button 
              className="legal-notice-button" 
              onClick={() => handleNavigation('/legal-notice')} 
              style={{ 
                position: 'absolute', 
                bottom: '10px', 
                left: '70px', 
                padding: '5px 10px', 
                fontSize: '12px', 
                background: 'none', 
                border: 'none', 
                color: 'black', 
                cursor: 'pointer' 
              }} 
            >
              特定商取引法に基づく表示
            </button>
          </>
        )}
      </div>
    </UserProvider>
  );
}

export default App;
