import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box, CircularProgress } from '@mui/material';

const PurchaseSuccess = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyPurchase = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('認証情報が見つかりません');
        setIsLoading(false);
        return;
      }

      try {
        const searchParams = new URLSearchParams(window.location.search);
        const sessionId = searchParams.get('session_id');
        
        console.log('Session ID from URL:', sessionId);

        if (!sessionId) {
          setError('セッションIDが見つかりません');
          setIsLoading(false);
          return;
        }

        const response = await axios.post(
          'https://us-central1-gggrace-gamer.cloudfunctions.net/api/verify-purchase',
          { sessionId },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        console.log('Verification response:', response.data);
        setIsLoading(false);

      } catch (error) {
        console.error('購入検証エラーの詳細:', error.response?.data || error.message);
        setError(error.response?.data?.message || '購入の検証中にエラーが発生しました');
        setIsLoading(false);
      }
    };

    verifyPurchase();
  }, []);

  if (isLoading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Box textAlign="center" py={4}>
          <Typography variant="h5" color="error" gutterBottom>
            エラーが発生しました
          </Typography>
          <Typography>
            {error}
          </Typography>
          <Typography variant="body2" color="textSecondary" mt={2}>
            サポートにお問い合わせください。
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box textAlign="center" py={4}>
        <Typography variant="h5" color="primary" gutterBottom>
          購入が完了しました
        </Typography>
        <Typography>
          通貨が付与されました。
        </Typography>
      </Box>
    </Container>
  );
};

export default PurchaseSuccess; 