import React from "react";
import "../styles/Modal.css";  // モーダル用のスタイル

const Modal = ({ show, handleClose, children, terms }) => {
  // モバイル判定
  const isMobile = window.innerWidth <= 768;

  return (
    <div className={`modal ${show ? "display-block" : "display-none"}`}>
      <div className="modal-content" style={{ maxHeight: isMobile ? '50vh' : 'auto', overflowY: isMobile ? 'auto' : '400px' }}>
        <span className="close-btn" onClick={handleClose}>&times;</span>
        {children}
        {terms && <div className="terms">{terms}</div>} {/* 利用規約を表示 */}
        <div className="modal-button-container" style={{ maxHeight: isMobile ? '50px' : 'auto', overflowY: isMobile ? 'auto' : '400px' }}>
        </div>
      </div>
    </div>
  );
};

export default Modal;
