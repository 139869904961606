import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../UserContext';
import { loadStripe } from '@stripe/stripe-js';
import '../styles/PointsPurchase.css';
import Modal from './Modal';

// 環境変数から直接Stripeの公開キーを取得
const STRIPE_PUBLIC_KEY = 'pk_test_51Q03d2P9yQeVQ1rFBy6Kw0zNyVNp26zXmcT1eDOaHdfxAxhBLQayVLd5hkBJnE42gfsS83qyxpRDsGeJ5StjxmmX00peVlABI9';

// Stripeの初期化を関数の外で行う
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const CurrencyPurchase = ({ userRole }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  // モバイル判定
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('Current token:', token);
    console.log('Current user:', user);
  }, [user]);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No token found');
        navigate('/login');
        return;
      }

      if (!user) {
        console.log('No user in context, fetching user data...');
        try {
          const response = await axios.get(
            'https://us-central1-gggrace-gamer.cloudfunctions.net/api/user',
            {
              headers: { Authorization: `Bearer ${token}` }
            }
          );
          if (!response.data) {
            console.log('No user data received');
            navigate('/login');
          } else {
            console.log('User data fetched:', response.data);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          if (error.response?.status === 401) {
            localStorage.removeItem('token');
            navigate('/login');
          }
        }
      }
    };

    checkAuth();
  }, [navigate, user]);

  const currencyOptions = [
    { price: 500, currency: 470, label: '470 GGG' },
    { price: 1000, currency: 960, label: '960 GGG' },
    { price: 3000, currency: 3100, label: '3,100 GGG' },
    { price: 5000, currency: 5200, label: '5,200 GGG' },
    { price: 10000, currency: 10500, label: '10,500 GGG' },
    { price: 15000, currency: 16000, label: '16,000 GGG' },
  ];

  const handlePurchase = async (option) => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('購入するにはログインが必要です。');
      navigate('/login');
      return;
    }

    if (!user) {
      console.log('ユーザー情報を再取得中...');
      try {
        const response = await axios.get(
          'https://us-central1-gggrace-gamer.cloudfunctions.net/api/user',
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        if (!response.data) {
          alert('ユーザー情報の取得に失敗しました。再度ログインしてください。');
          navigate('/login');
          return;
        }
      } catch (error) {
        console.error('ユーザー情報取得エラー:', error);
        alert('ユーザー情報の取得に失敗しました。再度ログインしてください。');
        navigate('/login');
        return;
      }
    }

    setSelectedPrice(option.price);
    setShowModal(true);
  };

  const handleConfirmPurchase = async () => {
    if (!agreedToTerms) {
      setShowTermsModal(true);
      setShowModal(false);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('セッションが切れました。再度ログインしてください。');
        navigate('/login');
        return;
      }

      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Stripeの初期化に失敗しました');
      }

      const selectedOption = currencyOptions.find(opt => opt.price === selectedPrice);
      if (!selectedOption) {
        throw new Error('選択された価格情報が見つかりません');
      }

      // デバッグ用のログ追加
      console.log('Sending request with data:', {
        amount: selectedOption.price,
        currency: selectedOption.currency,
        successUrl: `${window.location.origin}/purchase-success?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${window.location.origin}/currency-purchase`
      });

      const response = await axios.post(
        'https://us-central1-gggrace-gamer.cloudfunctions.net/api/create-checkout-session',
        {
          amount: selectedOption.price,
          currency: selectedOption.currency,
          successUrl: `${window.location.origin}/purchase-success?session_id={CHECKOUT_SESSION_ID}`,
          cancelUrl: `${window.location.origin}/currency-purchase`
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          // タイムアウト設定を追加
          timeout: 10000
        }
      );

      if (!response.data || !response.data.sessionId) {
        console.error('Invalid response:', response);
        throw new Error('セッションIDの取得に失敗しました');
      }

      console.log('Received session ID:', response.data.sessionId);

      setShowModal(false);

      const result = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId
      });

      if (result.error) {
        console.error('Stripe redirect error:', result.error);
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('購入処理エラーの詳細:', error.response?.data || error);
      setShowModal(false);
      
      if (error.response?.status === 401) {
        alert('認証が切れました。再度ログインしてください。');
        navigate('/login');
      } else if (error.response?.status === 500) {
        alert('サーバーエラーが発生しました。しばらく時間をおいて再度お試しください。');
      } else {
        alert('購入処理中にエラーが発生しました: ' + (error.response?.data?.error || error.message));
      }
    }
  };

  const termsText = `
    利用規約

    本利用規約（以下「本規約」といいます）は、合同会社[会社名]（以下「当社」といいます）が提供するオンライン通話サービス「GGG」（以下「本サービス」といいます）の利用条件を定めるものです。本サービスを利用される際には、本規約に同意したものとみなされます。

    第1条（適用）
    本規約は、本サービスの利用に関する当社とユーザーとの間の権利義務関係を定めるものであ、ユーザーは本規約に従って本サービスを利用するものとします。

  　第2条（定義）
本規約における用語の定義は以下の通りです。

「ユーザー」とは、本サービスを利用する18歳以上の個人を指します。
「アルバイト」とは、当社に所属し、本サービスを通じてユーザーと通話を行う従業員を指します。
「コンテンツ」とは、本サービスを通じて提供されるすべての音声、テキスト、画像、その他のデータを指します。

第3条（ユーザー登録）
ユーザーは、18歳以上であることを条件として、当社が定める手続きに従い、本サービスの利用登録を行うものとします。
登録情報は正確かつ最新のものであることが必要であり、変更が生じた場合、ユーザーは速やかに当社に通知するものとします。

第4条（サービスの内容）
本サービスは、ユーザーが当社のアルバイトとオンライン通話を行うことを目的としています。当社は通話相手を提供するものであり、ユーザーが通話中にどのような活動を行うか（例：オンラインゲームのプレイ）は、すべてユーザーの自由な判断によるものとします。
なお、ユーザーは、オンラインゲームの利用に関する規約や法律を自己責任で遵守するものとし、当社はこれに対する一切の責任を負いません。

第5条（禁止事項）
ユーザーは、以下の行為を行ってはならないものとします。

法令または公序良俗に反する行為
他のユーザーやアルバイトに対する嫌がらせや迷惑行為
当社または第三者の知的財産権を侵害する行為
本サービスを通じて取得した情報を無断で第三者に提供する行為
その他、当社が不適切と判断する行為

第6条（料金と支払い）
本サービスは有料で提供され、ユーザーは利用時間に応じた料金を当社が指定する方法で支払うものとします。
支払いは、クレジットカードや電子マネーを通じてStripeで行われ、支払済みの料金は原則として返金されません。ただし、当社が特別に認めた場合はこの限りではありません。

第7条（知的財産権）
本サービスに関するすべての知的財産権は、当社また正当な権利を有する第三者に帰属します。ユーザーは、当社の事前の許可なく、サービス内のコンテンツを複製、転用、販売、または頒布することはできません。

第8条（個人情報の取扱い）
当社は、ユーザーの個人情報を適切に管理し、本サービスの提供に必要な範囲でのみ利用します。
当社は、法令に基づく場合を除き、ユーザーの個人情報を第三者に提供することはありません。

第9条（免責事項）
当社は、ユーザーが本サービスを利用する際に生じた技術的な問題やサービス中のトラブルに対して一切の責任を負いません。また、ユーザーが通話中に行った他の行為（例：オンラインゲームのプレイ）に関連して発生した問題や法的責任についても、当社は一切関与せず、責任を負わないものとします。

第10条（サービスの利用停止・契約解除）
当社は、ユーザーが本規約に違反した場合、事前の通知なくアカウントを停止または契約を解除することができるものとします。
ユーザーは、いつでも本サービスの利用を停止し、アカウントの削除を申し出ることができます。

第11条（18歳未満の利用禁止）
本サービスは、18歳以上の方のみ利用可能です。18歳未満のユーザーが本サービスを利用した場合、当社は直ちにアカウントを停止し、必要に応じて法的措置を取ることができます。

第12条（準拠法および管轄裁判所）
本規約は日本法に準拠し、本サービスに関して生じた紛争については、[地域]地方裁判所を専属的合意管轄とします。

第13条（規約の変更）
当社は、必要に応じて本規約を変更することがあります。規約変更後にユーザーが本サービスを利用した場合、変更後の規約に同意したものとみなします。
  `;

  return (
    <div className="currency-purchase-container">
      <h2>GGG通貨チャージ</h2>
      <div className="purchase-options">
        {currencyOptions.map((option, index) => (
          <div key={index} className="purchase-card">
            {(option.price === 500 || option.price === 1000) && (
              <img src="https://firebasestorage.googleapis.com/v0/b/gggrace-gamer.appspot.com/o/GGG_coin%2FFrame%2010.png?alt=media&token=67fe9982-72e8-487b-99f8-492c03d8206d" alt="GGG Coin" className="currency-image" style={{ width: '60px', height: '60px' }} />
            )}
            {(option.price === 3000 || option.price === 5000) && (
              <img src="https://firebasestorage.googleapis.com/v0/b/gggrace-gamer.appspot.com/o/GGG_coin%2FFrame%209.png?alt=media&token=d6e5e3fb-c337-4b94-b6e5-3dd710e8d422" alt="GGG Coin" className="currency-image" style={{ width: '60px', height: '60px' }} />
            )}
            {(option.price === 10000 || option.price === 15000) && (
              <img src="https://firebasestorage.googleapis.com/v0/b/gggrace-gamer.appspot.com/o/GGG_coin%2FFrame%208.png?alt=media&token=c12dfceb-5632-4d86-95b9-f891cb9ab167" alt="GGG Coin" className="currency-image" style={{ width: '60px', height: '60px' }} />
            )}
            <div className="currency">{option.label}</div>
            <div className="price">¥{option.price.toLocaleString()}</div>
            <button onClick={() => handlePurchase(option)} className="purchase-button">
              チャージ
            </button>
          </div>
        ))}
      </div>
      <Modal show={showModal} handleClose={() => setShowModal(false)} style={{ height: isMobile ? 'auto' : '400px' }}>
        <h3>チャージ確認</h3>
        <p>{selectedPrice?.toLocaleString()}円分の通貨をチャージしますか？</p>
        <div className="modal-button-container">
          <button onClick={handleConfirmPurchase} className="yes-button">はい</button>
          <button onClick={() => setShowModal(false)} className="no-button">いいえ</button>
        </div>
      </Modal>
      <Modal show={showTermsModal} handleClose={() => setShowTermsModal(false)} style={{ height: isMobile ? 'auto' : '400px' }}>
        <h3>利用規約に同意しますか？</h3>
        <div className="terms">{termsText}</div>
        <div className="modal-button-container">
          <button onClick={() => { setAgreedToTerms(true); setShowTermsModal(false); }} className="agree-button">同意する</button>
          <button onClick={() => setShowTermsModal(false)} className="disagree-button">同意しない</button>
        </div>
      </Modal>
    </div>
  );
};

export default CurrencyPurchase;
