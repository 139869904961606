import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box, Grid, Button } from '@mui/material';
import { format, addDays, subDays, parseISO, addMinutes, subMinutes, isEqual, differenceInMinutes } from 'date-fns';
import { ja } from 'date-fns/locale';
import '../styles/CastDetail.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';


const CastDetail = () => {
  const { id } = useParams();
  const [castData, setCastData] = useState(null);
  const [schedule, setSchedule] = useState({});
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    const fetchCastData = async () => {
      try {
        const token = localStorage.getItem('token');
        const [castResponse, scheduleResponse] = await Promise.all([
          axios.get(`https://us-central1-gggrace-gamer.cloudfunctions.net/api/cast/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get(`https://us-central1-gggrace-gamer.cloudfunctions.net/api/get-cast-schedule/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
          })
        ]);

        setCastData(castResponse.data);
        setSchedule(scheduleResponse.data.schedule.timeSlots || {});
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cast data:', error);
        setLoading(false);
      }
    };

    fetchCastData();
  }, [id]);

  const isSlotSelectable = (dateString, time) => {
    if (!schedule[dateString] || schedule[dateString][time] !== true) return false;
    
    const slotKey = `${dateString}-${time}`;
    if (selectedSlots.length === 0) return true;
    if (selectedSlots.includes(slotKey)) return true;

    const slotDateTime = parseISO(`${dateString}T${time}`);
    const adjacentBefore = format(subMinutes(slotDateTime, 15), 'yyyy-MM-dd-HH:mm');
    const adjacentAfter = format(addMinutes(slotDateTime, 15), 'yyyy-MM-dd-HH:mm');
    
    return selectedSlots.includes(adjacentBefore) || selectedSlots.includes(adjacentAfter);
  };

  const handleSlotSelection = (dateString, time) => {
    const slotKey = `${dateString}-${time}`;
    console.log('Selecting slot:', slotKey); // デバッグ用

    if (!isSlotSelectable(dateString, time)) {
      console.log('Slot is not selectable'); // デバッグ用
      return;
    }

    setSelectedSlots((prevSlots) => {
      let newSlots;
      if (prevSlots.includes(slotKey)) {
        // 選択解除の処理
        const sortedSlots = prevSlots
          .map(slot => ({
            key: slot,
            dateTime: parseISO(slot.replace('-', 'T'))
          }))
          .sort((a, b) => a.dateTime - b.dateTime);

        const index = sortedSlots.findIndex(slot => slot.key === slotKey);
        
        // 端のスロットでない場合は解除しない
        if (index > 0 && index < sortedSlots.length - 1) {
          console.log('Cannot unselect middle slot'); // デバッグ用
          return prevSlots;
        }

        newSlots = prevSlots.filter(slot => slot !== slotKey);
      } else {
        // 新しいスロットの追加処理
        newSlots = [...prevSlots, slotKey].sort();
      }
      
      console.log('New selected slots:', newSlots); // デバッグ用
      const timeRange = getSelectedTimeRange(newSlots);
      console.log('Time range:', timeRange);
      
      updateErrorMessage(newSlots);
      return newSlots;
    });
  };

  const renderImageGallery = () => {
    if (!castData || !castData.imageUrls || castData.imageUrls.length === 0) {
      return null;
    }

    return (
      <fieldset id="gallery" className="hub">
        {castData.imageUrls.slice(0, 5).map((url, index) => (
          <div key={index} className={index === 0 ? 'main-image' : 'sub-image'}>
            <input
              type="radio"
              id={`image-${index}`}
              name="gallery"
              value={`image-${index}`}
              defaultChecked={index === 0}
            />
            <img src={url} alt={`${castData.name} - 画像 ${index + 1}`} />
            <label htmlFor={`image-${index}`}>{`画像 ${index + 1}`}</label>
          </div>
        ))}
      </fieldset>
    );
  };

  const getSelectedTimeRange = (slots) => {
    if (slots.length === 0) return '';
    
    console.log('Raw slots:', slots); // デバッグ用

    const sortedSlots = slots
      .map(slot => {
        console.log('Processing slot:', slot); // デバッグ用
        const [year, month, day, time] = slot.split('-');
        const [hour, minute] = time.split(':').map(Number);
        
        console.log('Parsed values:', { year, month, day, hour, minute }); // デバッグ用

        // 無効な日付や時間の値をチェック
        if (isNaN(Number(year)) || isNaN(Number(month)) || isNaN(Number(day)) || isNaN(hour) || isNaN(minute)) {
          console.error(`Invalid date or time: ${slot}`);
          return null;
        }
        
        const dateTime = new Date(Number(year), Number(month) - 1, Number(day), hour, minute);
        
        console.log('Created Date object:', dateTime); // デバッグ用

        // 無効な Date オブジェクトをチェック
        if (isNaN(dateTime.getTime())) {
          console.error(`Invalid Date object created from: ${slot}`);
          return null;
        }
        
        return {
          key: slot,
          dateTime: dateTime
        };
      })
      .filter(slot => slot !== null);

    console.log('Processed slots:', sortedSlots); // デバッグ用

    if (sortedSlots.length === 0) {
      console.error('No valid slots found');
      return 'Invalid time range';
    }

    sortedSlots.sort((a, b) => a.dateTime - b.dateTime);

    const startDateTime = sortedSlots[0].dateTime;
    const endDateTime = addMinutes(sortedSlots[sortedSlots.length - 1].dateTime, 15);

    console.log('Start DateTime:', startDateTime, 'End DateTime:', endDateTime); // デバッグ用

    const startTime = format(startDateTime, 'HH:mm');
    const endTime = format(endDateTime, 'HH:mm');
    const date = format(startDateTime, 'M月d日');

    const result = `${date} ${startTime} - ${endTime}`;
    console.log('Final result:', result); // デバッグ用

    return result;
  };

  const updateErrorMessage = (slots) => {
    if (slots.length < 4) {
      setErrorMessage('1時間以上（4枠以上）選択してください。');
    } else {
      setErrorMessage('');
    }
  };

  const renderSchedule = () => {
    const times = Array.from({ length: 96 }, (_, i) => {
      const hour = Math.floor(i / 4);
      const minute = (i % 4) * 15;
      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    });

    return (
      <Box className="schedule-container">
        <Box className="schedule-header">
          <Button onClick={() => setStartDate(subDays(startDate, 1))}>
            前日
          </Button>
          <Button onClick={() => setStartDate(addDays(startDate, 1))}>
            翌日
          </Button>
        </Box>
        <Box className="schedule-days">
          {[0, 1, 2].map((dayOffset) => {
            const currentDate = addDays(startDate, dayOffset);
            const dateString = format(currentDate, 'yyyy-MM-dd');
            return (
              <Box key={dateString} className="schedule-day">
                <Typography variant="h6">
                  {format(currentDate, 'M月d日(E)', { locale: ja })}
                </Typography>
                <Box className="schedule-hours">
                  {times.map((time) => {
                    const isAvailable = schedule[dateString] && schedule[dateString][time] === true;
                    const isSelected = selectedSlots.includes(`${dateString}-${time}`);
                    const selectable = isSlotSelectable(dateString, time);
                    return (
                      <Box
                        key={`${dateString}-${time}`}
                        className={`schedule-time-slot ${isAvailable ? 'available' : 'unavailable'} ${isSelected ? 'selected' : ''} ${selectable ? 'selectable' : ''}`}
                        onClick={() => isAvailable && handleSlotSelection(dateString, time)}
                      >
                        <Typography variant="body2" className="time">
                          {time}
                        </Typography>
                        <Typography variant="body2" className="availability">
                          {isAvailable ? (selectable ? '選択可能' : '予約可能') : '予約不可'}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <Box>
            {errorMessage && (
              <Typography color="error">
                {errorMessage}
              </Typography>
            )}
            {selectedSlots.length >= 4 && (
              <Button
                variant="contained"
                sx={{ 
                  backgroundColor: '#e74c3c', // 明るい赤に設定
                  color: '#fff',
                  transition: 'background-color 0.2s, transform 0.2s, box-shadow 0.2s', // トランジションを追加
                  '&:hover': { // ホバー時のスタイル
                    backgroundColor: '#FF3333', // 明るいホバー時の背景色
                    borderColor: '#FF3333', // ホバー時のボーダー色
                    transform: 'translateY(-2px)', // 上に移動
                    boxShadow: '0 4px 6px rgba(204, 0, 0, 0.2)', // ホバー時の影
                  }
                }} 
                onClick={() => {
                  // ここに予約処理を追加
                  console.log('Selected slots:', selectedSlots);
                }}
              >
                予約する
              </Button>
            )}
          </Box>
          <Typography>
            選択時間: {getSelectedTimeRange(selectedSlots)}
            (選択枠数: {selectedSlots.length})
          </Typography>
        </Box>
      </Box>
    );
  };

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const gallery = document.getElementById('gallery');
    if (gallery) {
      const inputs = gallery.querySelectorAll('input');
      inputs.forEach(radio => {
        radio.onclick = e => {
          if (!document.startViewTransition) return;
          
          e.preventDefault();
          
          function mutate() {
            e.target.checked = true;
            e.target.parentNode.style.zIndex = null;
          }
          
          e.target.parentNode.style.zIndex = 2;
          
          document.startViewTransition
            ? document.startViewTransition(() => mutate())
            : mutate();
        };
      });
    }

    const flipGallery = () => {
      const gallery = document.getElementById('gallery');
      if (gallery) {
        const vertical = window.innerWidth <= 768;
        if (document.startViewTransition) {
          document.startViewTransition(() => {
            vertical ? gallery.classList.add('portrait') : gallery.classList.remove('portrait');
          });
        } else {
          vertical ? gallery.classList.add('portrait') : gallery.classList.remove('portrait');
        }
      }
    };

    let resizeEndTimer;
    window.addEventListener('resize', () => {
      clearTimeout(resizeEndTimer);
      resizeEndTimer = setTimeout(flipGallery, 100);
    });

    flipGallery();

    return () => {
      window.removeEventListener('resize', flipGallery);
    };
  }, [castData]);

  // スマートフォンかどうかを判定する関数
  const isMobile = () => {
    return window.innerWidth <= 768; // 768px 以下をスマートフォンとみなす
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!castData) {
    return <Typography>Cast not found</Typography>;
  }

  return (
    <Container maxWidth="lg" className="cast-detail-container">
   
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Box className="image-gallery-container">
              {renderImageGallery()}
            </Box>
            <Box className="cast-info">
              <Typography variant="h4" gutterBottom>
                {castData.name}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                好きなゲーム: {castData.favoriteGame}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                専門: {castData.specialty}
              </Typography>
              {castData.audioUrl && (
                <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                  <Button
                    variant="contained"
                    startIcon={isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                    onClick={toggleAudio}
                  >
                    {isPlaying ? '一時停止' : '自己紹介を再生'}
                  </Button>
                  <audio ref={audioRef} src={castData.audioUrl} onEnded={() => setIsPlaying(false)} />
                </Box>
              )}
              <Typography variant="body1" paragraph>
                {castData.selfIntroduction}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="cast-schedule">
              <Typography variant="h5" gutterBottom>
                スケジュール
              </Typography>
              {renderSchedule()}
            </Box>
          </Grid>
        </Grid>
    </Container>
  );
};

export default CastDetail;